@import 'npm:aos/dist/aos.css';

@import 'colors';
@import 'animations';

::selection {
  background-color: $secondary;
}

html {
  @media (max-width: 767.98px) {
    font-size: 12px;
  }
}

body {
  font-family: Roboto, sans-serif;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

.btn-primary {
  &,
  &:hover,
  &:not(:disabled):not(.disabled):active {
    background-color: $primary;
    border-color: $primary;
  }

  &:hover {
    background-color: rgba($primary, 0.75);
    border-color: rgba($primary, 0.75);
  }

  &:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
  }
}

.bg-secondary {
  background-color: $secondary !important;
  background-image: linear-gradient(-224deg, $secondary 0%, $secondary-light 100%) !important;
}

a {
  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: $primary;
    text-decoration: underline;
    font-weight: 500;
  }

  &:hover {
    text-decoration: none;
  }

  &.white {
    color: $secondary-light;
  }
}

.djnd-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.djnd {
  font-family: 'Titillium Web';
  font-weight: 900;
  font-size: 1.5rem;
  color: $primary;

  &::before {
    content: 'DANES JE NOV DAN';
  }
}

h2 {
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 5rem;

  strong {
    font-weight: 500;
  }
}

.spaced-container {
  padding-top: 6.2rem;
  padding-bottom: 6.2rem;

  &-md {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &-sm {
    padding-top: 3.1rem;
    padding-bottom: 3.1rem;
  }
}

.page-padding {
  padding-left: 5rem;
  padding-right: 5rem;

  @media (max-width: 767.98px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar {
  padding: 1.7rem 0;

  .navbar-brand {
    font-size: 1.8rem;
    font-weight: 500;
    text-decoration: none;

    &,
    &:hover,
    &:active {
      color: $primary;
    }
  }

  .nav-item {
    font-size: 1.2rem;
    padding-left: 0;
    padding-right: 0;
    margin-right: 1.5rem;
    text-decoration: none;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .custom-select {
    border-radius: 0;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23320ca0' d='M12 15L8 9h8z'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: right -0.15rem center;
    padding-right: 2rem;
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 100px;

    @media (max-width: 767.98px) {
      margin-left: 0;
      font-size: 1rem;
    }
  }
}

.jumbotron {
  padding-top: 7.8rem;
  padding-bottom: 7.8rem;
  margin-bottom: 0;
  // min-height: calc(100vh - 10rem);

  @media (max-width: 767.98px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &.jumbotron-about {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  h1 {
    font-weight: 300;
    font-size: 4.3rem;

    strong {
      font-weight: 500;
    }
  }
}

.jumbo-image {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 80%;
  background-image: url('../img/site-landing@2x.jpg');
  background-size: cover;
  background-position: top center;

  @media (max-width: 767.98px) {
    position: static;
    width: 90%;
    padding-top: 110%;
    margin: 0 auto;
  }
}

.lead-boxes {
  text-align: center;

  & > div {
    margin-bottom: 1.5rem;
  }

  .lead-box {
    border: 1px solid $primary;
    height: 100%;
    padding: 1.5rem;

    &.lead-box--last {
      @media (max-width: 767.98px) {

        .lead-box-image,
        hr {
          display: none;
        }
      }
    }

    .lead-box-icon {
      height: 100px;
      margin-top: 1rem;
      margin-bottom: 2rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .lead-box-image {
      margin: -1.5rem -1.5rem 1.5rem -1.5rem;

      .img-fluid {
        width: 100%;
      }
    }

    h3 {
      font-size: 1.8rem;
      font-weight: 400;
      margin-top: 1rem;

      em {
        font-weight: 700;
      }
    }

    hr {
      width: 45%;
      height: 5px;
      border: 0;
      background-image: linear-gradient(to right, $primary 0%, $secondary 100%);
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.1rem;
      font-weight: 300;
      line-height: 1.4;
    }
  }
}

.wild-title {
  @media (max-width: 767.98px) {
    font-size: 2rem;
  }
}

.showcase-boxes {

  & > div {
    margin-bottom: 1.5rem;
    flex-basis: 25%;
    padding: 0 15px;
    @media (max-width: 991.98px) {
      flex-basis: 33%;
      flex-wrap: wrap;
    }
    @media (max-width: 767.98px) {
      flex-basis: 50%;
    }
    @media (max-width: 575.98px) {
      flex-basis: 100%;
    }
  }

  .showcase-box {
    display: block;
    border: 1px solid $primary;
    height: 100%;
    padding: 1.5rem;
    text-decoration: none;
    color: #000;

    &:hover {
      background-color: $secondary-light;
    }

    .showcase-box-icon {
      height: 61px;

      svg {
        height: 100%;
      }

      &.slo {
        height: 51px;
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 1.1rem;
      font-weight: 500;
      padding-top: 1rem;
      margin-bottom: 0;
    }
  }
}

.carousel {
  .images {
    display: flex;

    .image {
      display: flex;
      align-items: center;
      position: absolute;
      height: 100%;

      img {
        opacity: 0;
        transition: opacity 1s ease;
        width: 100%;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  dl {
    margin-left: 1rem;
    display: flex;
    height: 804px;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 991.98px) {
      height: auto !important;
    }

    dt {
      margin-left: 1rem;
      margin-bottom: 1rem;
      font-size: 1.8rem;
      line-height: 1.2;
      font-weight: 500;
      position: relative;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;

      &.active {
        color: #000;
      }

      &::before {
        content: '';
        position: absolute;
        left: -1rem;
        display: block;
        width: 5px;
        height: 100%;
        background-image: linear-gradient(to bottom, $primary 0%, $secondary 100%);
      }
    }

    dd + dt {
      margin-top: 1.5rem;
    }

    dd {
      margin-left: 1rem;
      font-size: 1.1rem;
      font-weight: 300;
      overflow: hidden;
      height: 0;
      transition: height 1s ease;

      & > div {
        .image {
          margin: 2rem 0;
          text-align: center;
        }
      }
    }

    dt.active + dd {
      height: auto;
    }
  }
}

.contact-box {
  padding-top: 4rem;
  padding-bottom: 4rem;
  height: 100%;
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: $secondary-light;
  border-radius: 50%;
  padding: 10px;

  .contact-icon {
    height: 100px;
    width: 100px;
    text-align: center;
    line-height: 100px;
  }

  h3 {
    font-size: 2.5rem;
    font-weight: 300;
    margin-top: 1rem;
  }

  p {
    font-weight: 300;
    font-size: 1.1rem;
  }
}

.contact-form {
  .form-group {
    position: relative;

    label {
      position: absolute;
      top: -0.9rem;
      left: .75rem;
      font-size: 1.1rem;
      font-weight: 300;
      padding: 0 .5rem;
      background-color: $primary;
    }

    .form-control {
      background: transparent;
      border-radius: 0;
      padding: 1.75rem 1.25rem;
      margin-top: 2rem;
      color: #fff;
      font-weight: 300;
    }
  }

  .btn {
    &,
    &:hover,
    &:not(:disabled):not(.disabled):active {
      padding: 0.4rem 2rem;
      color: #fff;
      background-color: rgba(#fff, 0.2);
      line-height: 1;
      vertical-align: bottom;
    }

    &:hover {
      background-color: rgba(#fff, 0.4);
    }

    &:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem rgba(#fff, 0.5);
    }

    .btn-icon {
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.25rem;
    }
  }
}

.footer {
  .footer-col {
    display: flex;
    align-items: center;

    @media (max-width: 1199.98px) {
      flex-direction: column;
    }
  }

  p {
    font-weight: 300;
    font-size: 0.9rem;
    margin: 0;

    @media (max-width: 1199.98px) {
      margin-bottom: 10px;
    }
  }

  hr {
    border-top: 1px solid #2e149a;
    margin-bottom: 3.1rem;

    @media (max-width: 1199.98px) {
      margin-bottom: 2rem;
    }
  }

  .heart {
    display: inline-block;
    width: 22px;
    height: 20px;
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="%23#{str-slice("#{$primary}", 2)}"%3e%3cpath d="M88.037 17.963A23.7 23.7 0 0 0 71.226 11a23.699 23.699 0 0 0-16.81 6.963L50 22.379l-4.416-4.416A23.699 23.699 0 0 0 28.774 11a23.7 23.7 0 0 0-16.811 6.963c-9.284 9.284-9.284 24.337 0 33.621L50 89.621l38.037-38.037c9.284-9.284 9.284-24.337 0-33.621"/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: center;
  }

  .btn.github {
    display: inline-block;
    padding: .15rem 1.5rem;
    color: #fff;

    // IE 10
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 50%;
    }

    svg {
      height: 13px;
    }
  }

  .logo {
    img {
      max-width: 140px;
    }
    a {
      display: block;
      width: 100%;
    }

    @media (max-width: 767.98px) {
      margin-bottom: 20px;
    }
  }

  .developed-by {
    margin-right: 20px;
  }

  .djnd-logo {
    width: 120px;
  }
}

.sm-text-align-center {
  @media (max-width: 1199.98px) {
    text-align: center;
  }
}
